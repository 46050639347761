@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: kalamee;
  src: url('./fonts/Kalameh_Regular.ttf');
}

body {
  background-color: var(--theme-bg-main);
  // font-family: kalamee !important;
}
* {
  scrollbar-width: 10px;
  scrollbar-color: var(--theme-bg-primary) var(--theme-bg-secondary);
}
// *::-moz-scrollbar-track
*::-webkit-scrollbar-track {
  background-color: var(--theme-bg-primary);
}

*::-webkit-scrollbar
// *::-moz-scrollbar
{
  width: 10px;
}

// *::-moz-scrollbar-thumb
*::-webkit-scrollbar-thumb {
  background-color: var(--theme-bg-secondary);
}
